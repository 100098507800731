<!--
--------------------------------------------------------------------------------
<copyright file="QuotesAbcCustomers.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.quotes_abc_customers') }}</summary>
      <p>
        {{ $t('widget_info_text.quotes_abc_customers_txt') }}
      </p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.quotes_abc_customers_tip') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.quotes_abc_customers_tip_dblclick') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.quotes_abc_customers_categories') }}</summary>
      <p class="text">{{ $t('widget_info_text.customers_groups_abc') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_a') }}</span>
          - {{ $t('widget_info_text.customer_group_a_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_b') }}</span>
          - {{ $t('widget_info_text.customer_group_b_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_c') }}</span>
          - {{ $t('widget_info_text.customer_group_c_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.quotes_abc_customers_tooltip') }}</summary>
      {{ $t('widget_info_text.quotes_abc_customers_tooltip_txt') }}
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{
            $t('widget_info_text.quotes_abc_customers_tooltip_customer_and_category')
          }}</span>
        </li>
        <li class="list-item">
          <span class="demi-bold">{{
            $t('widget_info_text.quotes_abc_customers_tooltip_amount')
          }}</span>
          -
          {{ $t('widget_info_text.quotes_abc_customers_tooltip_amount_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{
            $t('widget_info_text.quotes_abc_customers_tooltip_converted_amount')
          }}</span>
          -
          {{ $t('widget_info_text.quotes_abc_customers_tooltip_converted_amount_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{
            $t('widget_info_text.quotes_abc_customers_tooltip_number_quotes')
          }}</span>
        </li>
        <li class="list-item">
          <span class="demi-bold">{{
            $t('widget_info_text.quotes_abc_customers_tooltip_number_converted_quotes')
          }}</span>
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <details>
        <summary>
          <span class="list">{{ $t('widget_info_text.more_filter') }} </span>
        </summary>
        <img src="images/info/threeDotsMenu.jpg" class="list" />
        <quick-search />
      </details>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import QuickSearchFilter from './QuickSearchFilter.vue';

@Component({
  components: {
    'quick-search': QuickSearchFilter,
  },
})
export default class QuotesAbcCustomersWidgetInfo extends Vue {}
</script>
